import { useRef,useState,useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

export const Comparator = (props) => {
    const {UUID,
        translator,
        legendTemplate,
        options,        
        blocks,
        blockLookUp,
        characteristics,
        characteristicLookUp,
        fileContent,
        setUploadVisible,
        setComparatorVisible
        } = props;

    let reference = Object.entries(fileContent[0]);
    fileContent.forEach((file,index)=>{
        let filename = Object.keys(file);
        if (/reference/i.test(filename[0]))
            reference = Object.entries(fileContent[index]);                    
    });
    let baseHeaderTitle = reference[0][0];
    let baseHeaderClasses = reference[0][1]['LCT_Class'];
    let baseHPs = reference[0][1]['LCT_HorizontalPatterns'];
    let baseStrata = reference[0][1]['LCT_Strata'];
    let baseProperties = reference[0][1]['LCT_Properties'];

    let baseElements = {};
    let rowColumns = [];
    let colHeader = [];
    baseHeaderClasses.map((clss)=>{
        colHeader.push(<Column header={clss['class_name']} colSpan={2} />);
        rowColumns.push(<Column key={"E"+clss['class_id']} header={"Matching Elements"} sortable filter field={"ClassElements"+clss['class_id']} />);
        rowColumns.push(<Column key={"P"+clss['class_id']} header={"Similarity"} field={"ClassPercentage"+clss['class_id']} />);
        let elements = [];
        let filterd_HPs = baseHPs.filter( HP => HP.class_id === clss['class_id'] );
        filterd_HPs.map((HP)=>{
            let filterd_Strata = baseStrata.filter( Strata => Strata.HPID === HP['horizontal_pattern_id'] );
            filterd_Strata.map((Strata)=>{
                let filterd_Properties = baseProperties.filter( Properties => Properties.StratumID === Strata['stratumID'] );
                filterd_Properties.map((Properties)=>{
                    elements.push(Properties['BlockID']);
                });
            });
        });
        baseElements = {...baseElements, [clss['class_id']]: elements};
    });
    
    let similarityAssessmentResults = [];    
    fileContent.map((file)=>{        
        let subject = Object.entries(file);        
        let subjectHeaderTitle = subject[0][0];
        let subjectHeaderClasses = subject[0][1]['LCT_Class'];
        let subjectHPs = subject[0][1]['LCT_HorizontalPatterns'];
        let subjectStrata = subject[0][1]['LCT_Strata'];
        let subjectProperties = subject[0][1]['LCT_Properties'];

        let subjectElements = {};        
        subjectHeaderClasses.map((clss)=>{
            let elements = [];
            let filterd_HPs = subjectHPs.filter( HP => HP.class_id === clss['class_id'] );
            filterd_HPs.map((HP)=>{
                let filterd_Strata = subjectStrata.filter( Strata => Strata.HPID === HP['horizontal_pattern_id'] );
                filterd_Strata.map((Strata)=>{
                    let filterd_Properties = subjectProperties.filter( Properties => Properties.StratumID === Strata['stratumID'] );
                    filterd_Properties.map((Properties)=>{
                        elements.push(Properties['BlockID']);
                    });
                });
            });
            subjectElements = {...subjectElements, [clss['class_id']]: {elements: elements, class_name: clss['class_name']}};
        });
        let subjectResults = {};
        Object.entries(subjectElements).forEach((clss)=>{
            let test = clss[1]['elements'] as any;
            let classResults = [];
            Object.entries(baseElements).forEach((baseclss)=>{
                let base = baseclss[1] as any;
                let intersection = test.filter(x => base.includes(x));
                subjectResults = {...subjectResults, [clss[0]]: {[baseclss[0]]: intersection}};
                let percentage = Math.round((intersection.length/base.length)*100);
                let percentageDisplay = [];
                if(isNaN(percentage)){
                    percentage = null;
                    percentageDisplay = ['#F5F5F5', 'lighter', 'grey'];
                }
                else if(percentage < 0)
                    percentageDisplay = ['#F5F5F5', 'lighter', 'grey'];
                else if(percentage >= 0 && percentage <= 10)
                    percentageDisplay = ['rgba(255,255,255,0)', 'normal', 'black'];
                else if(percentage >= 10 && percentage <= 20)
                    percentageDisplay = ['#E1F1E8', 'normal', 'black'];
                else if(percentage >= 20 && percentage <= 40)
                    percentageDisplay = ['#C3E5CD', 'normal'];
                else if(percentage >= 40 && percentage <= 60)
                    percentageDisplay = ['#ACDCBA', 'bold', 'black'];
                else if(percentage >= 60 && percentage <= 80)
                    percentageDisplay = ['#91D1A2', 'bold', 'black'];
                else if(percentage >= 80 && percentage <= 100)
                    percentageDisplay = ['#63BE7B', 'bolder', 'white'];
                else
                    percentageDisplay = ['#477F56', 'bolder', 'white'];

                classResults.push({['ClassElements'+baseclss[0]]: intersection.length, ['ClassPercentage'+baseclss[0]]: <div className="flex align-items-center justify-content-center p-2 m-0" style={{ width: 'fit-contents', height: '20px', borderRadius: '5px', textAlign: 'center', background: percentageDisplay[0], fontWeight: percentageDisplay[1], color: percentageDisplay[2] }} >{percentage}%</div>});
            });
            let tempResults = {};
            classResults.map((result)=>{
                tempResults = {...tempResults, ...result};
            });
            similarityAssessmentResults.push({ file: subjectHeaderTitle, class: <b>{clss[1]['class_name']}</b>, ...tempResults });
        });
    });
    const [results] = useState<any>(similarityAssessmentResults);

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Classes" rowSpan={3} />
                <Column header={"Reference Classes: "+baseHeaderTitle} colSpan={rowColumns.length} />
            </Row>
            <Row>
                {colHeader}
            </Row>
            <Row>                
                {rowColumns}
            </Row>
        </ColumnGroup>
    );

    const headerTemplate = (data) => {
        return (
            <div className="flex align-items-center gap-2" style={{backgroundColor: '#eee'}}>
                <span className="font-bold">{data.file}</span>
            </div>
        );
    };
    
    
    return (
        <>
            <div className="card m-0 p-0" style={{ height: '4vh', minHeight: '20px' }}>
                <img alt="Land CHaracterization Software" src="logo.png" height="30" className="mr-2"></img>            
                <img alt="Food and Agriculture Organization of the United Nations" src="fao-logo.png" height="30" className="mr-2"></img>
                <div className="card flex justify-content-center">
                    <Button label="Back" icon="pi pi-step-backward-alt" size="small" onClick={()=>{setUploadVisible(true);setComparatorVisible(false);}} style={{ backgroundColor: 'var(--highlight-bg)', color: 'var(--error-100)', borderRadius: 'var(--border-radius)' }}  className="mr-2 text-xs p-1 m-1" />
                </div>
            </div>
            <div className="card flex flex-row gap-2 m-1 p-0">
                <Card title="Assessment Results" style={{ width: '98vw', height: '93vh', overflow: 'hidden' }}>
                    <DataTable value={results} headerColumnGroup={headerGroup} rowGroupMode="subheader" groupRowsBy="file" rowGroupHeaderTemplate={headerTemplate} stripedRows size='small' scrollable removableSort scrollHeight="82vh" tableStyle={{ minWidth: '50rem' }}>
                        <Column field="class" frozen />
                        {rowColumns}
                    </DataTable>
                </Card>
            </div>
        </>
    )
}
