import React, { useState,useRef,useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Button } from "primereact/button";
import { Controller, useForm } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel, TabViewTabChangeEvent } from 'primereact/tabview';
import { ColorPicker, ColorPickerChangeEvent } from 'primereact/colorpicker';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Checkbox } from "primereact/checkbox";
import { ConfirmDialog } from "primereact/confirmdialog";

export const GuideLegendCreation2 = (props:any) => {
    const {UUID,
            legend,
            classCharacteristics,
            setRerenderTrigger,
            setGuide1Visible,
            setGuide2Visible,
            setGuide3Visible,
            LC_Legend,
            LC_Class,
            classesNumber,
            LC_ClassCharacteristics,
            LC_HorizontalPatterns,
            LC_Strata,
            horizontalPatternNumber,
            strataNumber,
            LC_Properties,
            stratumPropertyNumber,
            LC_Characteristics,
            stratumCharacteristicNumber,
            displayFormElements,
            control,
            errors,
            register,
            handleSubmit,
            getValues,
            setValue,
            watch,
            reset,
            searchObjKeyVal,
            OptionsBus
        } = props;

    const toast = useRef(null);

    const class_id = useRef(classesNumber);
    const class_name = useRef<string>('Class '+classesNumber);
    const class_description = useRef<string>('The land characterization class');
    const class_map_code = useRef<string>('LCC'+classesNumber);
    const class_color_code = useRef<string>('FFFFFF');
    let classes = LC_Class.current;
    const [displayClasses,setClasses] = useState(classes);

    const onSubmit = (data: any,event) => {
        event.preventDefault();
        let class_id = parseInt((document.getElementById("class_id"+event.target.id) as HTMLInputElement).value);      
        classes.splice(event.target.id,1, {
                                legend_id:parseInt((document.getElementById("legend_id"+event.target.id) as HTMLInputElement).value),
                                class_id:class_id,
                                class_name:(document.getElementById("class_name"+event.target.id) as HTMLInputElement).value,
                                class_description:(document.getElementById("class_description"+event.target.id) as HTMLInputElement).value,
                                class_map_code:(document.getElementById("class_map_code"+event.target.id) as HTMLInputElement).value,
                                class_color_code:(document.getElementById("class_color_code"+event.target.id) as HTMLInputElement).value
                            });        

        let formValues = getValues();
        let CharNames = [];
        let Charact = {};
        Object.entries(formValues).forEach((tab)=>{            
            if(tab[0].indexOf("-") > -1){
                let IDlength = (document.getElementById("class_id"+event.target.id) as HTMLInputElement).value.length*-1;                
                tab[0] = tab[0].slice(0, IDlength);
                LC_ClassCharacteristics.current[class_id] = null;
                let fieldName = tab[0].split('-');
                CharNames.push(fieldName[0]);
                let unique = new Set(CharNames);                
                unique.forEach((Char)=>{
                    if(fieldName[0] === Char){                        
                        if(typeof(tab[1]) === "object"){
                            if(tab[1]['label'] !== undefined)
                                Charact = {...Charact, [Char]: {...Charact[Char], [tab[0]]:tab[1]['label']}};
                            else
                                Charact = {...Charact, [Char]: {...Charact[Char], [tab[0]]:tab[1]}};
                        }                        
                        else
                            Charact = {...Charact, [Char]: {...Charact[Char], [tab[0]]:tab[1]}};                        
                    }                    
                });
                LC_ClassCharacteristics.current[class_id] = Charact;
            }
        });
        toast.current.show({ severity: 'success', summary: 'Class saved', detail: (document.getElementById("class_name"+event.target.id) as HTMLInputElement).value+" saved successfully." });
        if((document.getElementById("addClass"+event.target.id) as HTMLInputElement).checked){
            classesNumber.current = classesNumber.current+1;
            classes = [...classes, {legend_id: LC_Legend.current.id, class_id: classesNumber.current, class_name: 'Class '+classesNumber.current, class_description: 'The land characterization class', class_map_code: 'LCR'+classesNumber.current, class_color_code: 'FF0000'}];
        }
        setClasses(classes);
    };    

    const getFormErrorMessage = (name:any) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    let Characteristics = Object.entries(classCharacteristics.current);
    let Tabs = {};
    let resetter = [];
    const resets = useRef<any>();
    displayClasses.map((clss, index) => {
        let SubTabs = [];
        Characteristics.map((characteristics)=>{
            let tabelements = [];
            tabelements.push(displayFormElements(characteristics[1]["elements"],index));
            let tab = <TabPanel header={characteristics[1]['characteristic_label']} leftIcon={characteristics[1]['characteristic_icon']}  className="card flex flex-column overflow-hidden" style={{ height: 'fit-content' }} >
                            <p className="p-inputtext-sm text-s justify-content-center" style={{ backgroundColor: 'var(--highlight-bg)' }}><b><i className="pi pi-info-circle" style={{ color: 'slateblue' }}></i><u style={{textAlign: "center"}}>{characteristics[1]['characteristic_description']}</u></b></p>
                            {tabelements}
                        </TabPanel>;
            SubTabs.push(tab);
            characteristics[1]["elements"].map((element)=>{                
                if(LC_ClassCharacteristics.current[clss.class_id] !== undefined && Object.keys(LC_ClassCharacteristics.current[clss.class_id]).length !== undefined && LC_ClassCharacteristics.current[clss.class_id][characteristics[1]['characteristic_name']] !== undefined){
                    let prevValue = LC_ClassCharacteristics.current[clss.class_id][characteristics[1]['characteristic_name']][element['element_name']];
                    if(typeof(prevValue) === "undefined" || prevValue === null || prevValue === "")
                        resetter.push('setValue("'+element['element_name']+index+'", "")');
                    else if(typeof(prevValue) === "object")
                        resetter.push('setValue("'+element['element_name']+index+'", ['+prevValue[0]+','+prevValue[1]+'])');
                    else if(typeof(prevValue) === "number")
                        resetter.push('setValue("'+element['element_name']+index+'", '+prevValue+')');
                    else
                        resetter.push('setValue("'+element['element_name']+index+'", "'+prevValue+'")');                    
                }
            });             
            resets.current = resetter;
        });
        Tabs = {...Tabs, [index]: SubTabs};
    });

    const formReset = ()=>{
        resets.current?.map((formElement)=>{
            eval(formElement);
        });
    }
    
    const [confirmClassDelete, setConfirmClassDelete] = useState<boolean>(false);    
    const ClassToDelete = useRef<number>(null);
    const promptClassDelete = (index)=>{        
        setConfirmClassDelete(true);
        ClassToDelete.current = index;
    }    
    const acceptClassDelete = (index) => {        
        let ClassName = displayClasses[index].class_name;
        deleteClass(index);
        toast.current?.show({ severity: 'warn', summary: 'Confirmed', detail: 'Class '+ClassName+' deleted!', life: 3000 });
    }    
    const reject = () => {
        toast.current?.show({ severity: 'info', summary: 'Cancelled', detail: 'Action Cancelled.', life: 3000 });
    }

    const deleteClass = (index)=> {
        let class_id = displayClasses[index]['class_id'];
        let filterd_CXteristics = {};
        Object.entries(LC_ClassCharacteristics.current).forEach((CChr)=>{
            if(parseInt(CChr[0]) !== class_id)
                filterd_CXteristics = {...filterd_CXteristics, [CChr[0]]: CChr[1]};
        });
        let filterd_classes = [];
        Object.entries(displayClasses).forEach((clss)=>{
            if(parseInt(clss[0]) !== index)
                filterd_classes.push(clss[1]);
        });     
        LC_Class.current = filterd_classes;
        LC_ClassCharacteristics.current = filterd_CXteristics;
        setClasses(filterd_classes);
        classes = LC_Class.current;

        let deletd_HPs = LC_HorizontalPatterns.current.filter(HPs => HPs.class_id === class_id);        
        if(deletd_HPs !== undefined){
            deletd_HPs.map((deletd_HP)=>{
                deleteHorizontalPattern(deletd_HP.horizontal_pattern_id);
            });
        }
    }
    const deleteHorizontalPattern = (HPID)=>{
        let deletd_strata = LC_Strata.current.filter(stratum => stratum.HPID === HPID);
        if(deletd_strata !== undefined){
            deletd_strata.map((deletd_stratum)=>{
                deleteStratum(deletd_stratum.stratumID);
            });
        }
        let undeletd_HPs = LC_HorizontalPatterns.current.filter(HP => HP.horizontal_pattern_id !== HPID);        
        LC_HorizontalPatterns.current = [...(undeletd_HPs || [])];
        horizontalPatternNumber.current = undeletd_HPs.length+1;
        setRerenderTrigger('Deleted Horizontal Pattern'+horizontalPatternNumber.current);
    }
    const deleteStratum = (StratumID)=>{
        let undeletd_properties = LC_Properties.current.filter(properties => properties.StratumID !== StratumID);
        if(undeletd_properties !== undefined){
            LC_Properties.current = [...(undeletd_properties || [])];
            stratumPropertyNumber.current = undeletd_properties.length+1;
        }
        let undeletd_characteristics = LC_Characteristics.current.filter(characteristics => characteristics.StratumID !== StratumID);
        if(undeletd_characteristics !== undefined){
            LC_Characteristics.current = [...(undeletd_characteristics || [])];
            stratumCharacteristicNumber.current = undeletd_characteristics.length+1;
        }
        let undeletd_strata = LC_Strata.current.filter(stratum => stratum.stratumID !== StratumID);
        LC_Strata.current = [...(undeletd_strata || [])];
        strataNumber.current = undeletd_strata.length+1;
        setRerenderTrigger('Deleted Strata'+strataNumber.current);
    }

    const createDynamicTabs = () => {
        return displayClasses.map((tab, index) => {
            return (                
                <AccordionTab key={tab.class_id} header={'Class '+(index+1)+' - '+tab.class_name}>
                    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-1" id={index}>                    
                        <div className="card flex flex-row justify-content-center">
                            <Card title="Step2: Class Definition" subTitle="Input Land Cover Class Definition" className="w-full" style={{ width: '50%', background: '#eee' }}>
                                <p className="m-0">
                                    Define the Land Cover Class and Characteristics: Climate, Land Form, Geographical Aspects, Topographical Aspects and Surface Characteristics
                                </p>
                            </Card>
                            <Divider layout="vertical" />
                            <div className="card align-contents-top w-full">                                      
                                <div className="w-full card justify-content-center gap-3" style={{width: '50%'}}>
                                    <Controller
                                    name={"legend_id"+index}
                                    control={control}
                                    defaultValue={LC_Legend.current.id}
                                    render={({ field, fieldState }) => (
                                        <input type="hidden" id={field.name} name={field.name} value={field.value} />
                                        )}
                                    />                                    
                                    <Controller
                                    name={"class_id"+index}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <input type="hidden" id={field.name} name={field.name} value={tab.class_id} />
                                        )}
                                    />
                                    <Controller
                                    name={"class_name"+index}
                                    control={control}                                    
                                    render={({ field, fieldState }) => (
                                        <p className="card flex w-full p-inputgroup mt-3 pt-1">
                                            <span className="p-inputgroup-addon text-s p-0 m-0">
                                                <i className="pi pi-pencil"></i>
                                            </span>
                                            <span className="p-float-label w-full">                        
                                                <InputText autoFocus id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-s  p-2 m-0" onChange={(e) => field.onChange(e.target.value)} style={{ width: '100%'}} defaultValue={tab.class_name} required />
                                                <label htmlFor={field.name}>Class Name</label>
                                            </span>
                                            {getFormErrorMessage(field.name)}
                                        </p>
                                        )}
                                    />
                                    <Controller
                                    name={"class_description"+index}
                                    control={control}                                    
                                    render={({ field, fieldState }) => (
                                        <p className="card flex w-full p-inputgroup mt-3 pt-1">
                                            <span className="p-inputgroup-addon text-s p-0 m-0">
                                                <i className="pi pi-pencil"></i>
                                            </span>
                                            <span className="p-float-label w-full">                        
                                                <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-s  p-2 m-0" onChange={(e) => field.onChange(e.target.value)} style={{ width: '100%'}} defaultValue={tab.class_description} required />
                                                <label htmlFor={field.name}>Description</label>
                                            </span>
                                            {getFormErrorMessage(field.name)}
                                        </p>
                                        )}
                                    />
                                    <Controller
                                    name={"class_map_code"+index}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <p className="card flex w-full p-inputgroup mt-3 pt-1">
                                            <span className="p-inputgroup-addon text-s p-0 m-0">
                                                <i className="fa-solid fa-map"></i>
                                            </span>
                                            <span className="p-float-label w-full">                        
                                                <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-s  p-2 m-0" onChange={(e) => field.onChange(e.target.value)} style={{ width: '100%'}} defaultValue={tab.class_map_code} />
                                                <label htmlFor={field.name}>Map Code</label>
                                            </span>
                                            {getFormErrorMessage(field.name)}
                                        </p>
                                        )}
                                    />
                                    <Controller
                                    name={"class_color_code"+index}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <p className="card flex w-full p-inputgroup mt-3 pt-1">
                                            <span className="p-inputgroup-addon text-s p-0 m-0">
                                                <i className="pi pi-palette"></i>
                                            </span>                                                            
                                            <ColorPicker format="hex" className="p-0 m-0" onChange={(e) => field.onChange(e.value)} style={{ width: '40px' }} defaultColor={tab.class_color_code} />
                                            <p className="flex align-self-center p-0 m-0">&lt;--select color here #</p>
                                            <InputText id={field.name} name={field.name} value={field.value} className="p-inputtext-sm text-s  p-2 m-0" style={{ width: '30%'}} defaultValue={tab.class_color_code} prefix="#" />
                                            {getFormErrorMessage(field.name)}
                                        </p>
                                        )}
                                    />                                    
                                </div>
                            </div>
                        </div>

                        <div className="card flex flex-column justify-content-center p-0 m-0">
                            <TabView className="card flex flex-row justify-content-center p-0 m-0 overflow-hidden" >
                                {Tabs[index]}
                            </TabView>                    
                            <div className="flex" style={{ justifyContent: 'flex-end' }} >
                                <>{formReset()}</>                                
                                <Button label="Delete Class" icon="pi pi-times-circle" type="button" onClick={()=>promptClassDelete(index)} size="small" iconPos="top" style={{ backgroundColor: 'var(--red-100)', color: 'var(--error-100)', borderRadius: 'var(--border-radius)' }} disabled={lastClass} />
                                <div className="flex flex-column align-items-center justify-content-center p-0 m-1">
                                    <Controller
                                        name={"addClass"+index}
                                        control={control}                                    
                                        render={({ field, fieldState }) => (                                        
                                            <Checkbox inputId={field.name} checked={field.value} inputRef={field.ref} onChange={(e) => field.onChange(e.checked)} />                                        
                                        )}
                                    />
                                    <p className="text-xs">Add Class</p>
                                </div>
                                <Button label="Save Class" icon="pi pi-check-circle" type="submit" size="small" iconPos="top" style={{ backgroundColor: 'var(--highlight-bg)', color: 'var(--error-100)', borderRadius: 'var(--border-radius)' }} />                                
                            </div>
                        </div>
                    </form>
                </AccordionTab>            
            );
        });
    };

    const processClasses = ()=> {        
        LC_Class.current = classes;

        let empty_class_names = searchObjKeyVal(LC_Class.current,'class_name','');
        let empty_class_descriptions = searchObjKeyVal(LC_Class.current,'class_description','');        
        if(empty_class_names.length > 0 || empty_class_descriptions.length > 0){
            let displayNames = "";
            empty_class_names.map((clss)=>{
                displayNames = displayNames+clss.class_id+", "
            });
            displayNames = "Missing Class Name on Class: "+displayNames;
            let displayDescription = "";
            empty_class_descriptions.map((clss)=>{
                displayDescription = displayDescription+clss.class_id+", "
            });
            displayDescription = "Missing Class Description on Class: "+displayDescription;
            toast.current.show({ severity: 'error', summary: 'Class Error', detail: displayNames+" "+displayDescription });
        }        
        else{
            toast.current.show({ severity: 'success', summary: 'Classes saved', detail: LC_Class.current.length+" Classes." });
            const timer = setTimeout(() => {
                setGuide2Visible(false);
                setGuide3Visible(true);
                setRerenderTrigger('Classes');
            }, 1000);
        }        
    }

    const [lastClass,setLastClass] = useState(true);
    const [classMisMatch,setClassMisMatch] = useState(true);
    useEffect(()=>{
        if(displayClasses.length < 2)
            setLastClass(true);
        else
            setLastClass(false);
        if(LC_Class.current.length !== displayClasses.length)
            setClassMisMatch(true);
        else
            setClassMisMatch(false);
    },[displayClasses.length,LC_Class.current.length]);
    return (
        <div className="card flex flex-column  justify-content-center">
        <Toast ref={toast} />
        <ConfirmDialog visible={confirmClassDelete} onHide={() => setConfirmClassDelete(false)} message="Are you sure you want to delete the Class? This will delete all Horizontal Patterns, Strata, Elements, Properties and Characteristics associated with it." header="Delete Class Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={()=>acceptClassDelete(ClassToDelete.current)} reject={reject} />
            <Accordion activeIndex={0}>
                {createDynamicTabs()}
            </Accordion>
            <div className="card" style={{ textAlign: 'right' }} >
                <Button label="Back" icon="pi pi-arrow-left" type="button" onClick={() => {setGuide1Visible(true);setGuide2Visible(false);setRerenderTrigger('Class');}} text size="small" className="mr-1" />                
                <Button label="Next" icon="pi pi-arrow-right" type="button"  onClick={() => processClasses()} size="small" iconPos="right" className="mr-1" disabled={classMisMatch} />
            </div>
        </div>
    )
}
