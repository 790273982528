import React, { useState } from 'react';
import mermaid from 'mermaid';
import * as htmlToImage from 'html-to-image';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { saveAs } from 'file-saver';

mermaid.initialize({
    startOnLoad: true,
    theme: 'default',
    flowchart: { useMaxWidth: true, htmlLabels: true, curve: 'stepBefore' },
    themeVariables: { primaryColor: '#eef2ff', primaryTextColor: '#4b5563', primaryBorderColor: '#6366f1', lineColor: '#F8B229', fontSize: '9px', fontFamily: 'var(--font-family)' },
    securityLevel: 'loose',
    maxTextSize: 9999999,
    maxEdges: 9999999,
    logLevel: 5    
   });

export default class MermaidChartRender extends React.Component {
    componentDidMount() {
     mermaid.contentLoaded();
    }
   
    componentDidUpdate(prevProps, prevState) {
       if (prevProps.chart !== this.props.chart) {
         document
           .getElementById(this.props.index)
           .removeAttribute("data-processed");      
         mermaid.contentLoaded();
       }
     }
    //const { chart, legendValid, setActiveLCElement, index } = props;
    render() {
      const altCaptureTabImage = async (index) => {
        const svgElement = document.getElementById(index).querySelector('svg');
        if (svgElement) {
          const svgData = new XMLSerializer().serializeToString(svgElement);
          const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
          saveAs(blob, index+`.svg`);
        }
      };
      const downloadChartAsImage = async (index) => {          
          await htmlToImage.toPng(document.getElementById(this.props.index), { cacheBust: true }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas;
            link.download = 'LChS_Legend.png';
            link.click();
          }).catch(function (error) {
            console.error('Trying Again!', error);
            altCaptureTabImage(index);
          });
      };  
      const handleNodeClick = (event) => {        
          const clickedNode = event.target.closest('.node');    
          if (clickedNode) {
            const node = clickedNode.id || clickedNode.getAttribute('data-node-id');
            const regexClass = new RegExp('^A\\d+');
            const regexHP = new RegExp('^C_1_\\d+_1_\\d+');
            const regexStratum = new RegExp('^D_1_\\d+_1_\\d+_\\d+');
            const regexProperty = new RegExp('^E_1_\\d+_1_\\d+_\\d+_\\d+');
            const regexCharacteristic = new RegExp('^F_1_\\d+_1_\\d+_\\d+_\\d+_\\d+');
            let nodeID = node.split('-')[1];
            if(nodeID === 'A')
              this.props.setActiveLCElement('A|1');
            else if(regexClass.test(nodeID))
              this.props.setActiveLCElement('A|1|'+nodeID.replace('A',''));
            else if(regexHP.test(nodeID))
              this.props.setActiveLCElement('A'+nodeID.replace('C','').replaceAll('_','|'));
            else if(regexStratum.test(nodeID))
              this.props.setActiveLCElement('A'+nodeID.replace('D','').replaceAll('_','|'));
            else if(regexProperty.test(nodeID))
              this.props.setActiveLCElement('A'+nodeID.replace('E','').replaceAll('_','|'));
            else if(regexCharacteristic.test(nodeID)){
              let text = nodeID.replace('F','A').split('_');
              text.pop();
              text = text.join('|');
              this.props.setActiveLCElement(text);
            }
            else
            this.props.setActiveLCElement(null);
          } else {
            this.props.setActiveLCElement(null);
          }
        };
      
      return (
        <>          
          <div className="flex-column" style={{ width: '100%', height: '100%', overflow: 'auto' }}>            
              <div className="mermaid flex align-items-center justify-content-center" id={this.props.index} onClick={handleNodeClick} style={{ width: '100%', height: 'fit-contents', margin: 'auto', padding: 0, paddingLeft: '10px', overflow: 'auto' }}>
                  {this.props.chart}
              </div>
              <div style={{ position: 'absolute', top: '55%' ,width: 'fit-contents', overflow: 'hidden', height: 'fit-contents' }}>
                  <div style={{ width: '65px', borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', borderColor: 'var(--blue-600)', padding: '3px', margin: '2px' }}>
                      <Tooltip target=".custom-target-icon" />                
                      <i className="custom-target-icon pi pi-download align-contents-center justify-contents-center" data-pr-tooltip="Validate the legend to enable export" data-pr-position="right" data-pr-at="right+5 top" data-pr-my="left center-2" style={{ verticalAlign: 'center', fontSize: 'small', width: '55px' }}> 
                        <Button label="Export" onClick={()=>downloadChartAsImage(this.props.index)} style={{ position: 'absolute', bottom: 0, left: 0, height: '25px', backgroundColor: '#fff', color: 'var(--error-100)', borderRadius: 'var(--border-radius)', padding: '5px', fontSize: 'xx-small',marginLeft: '20px',top: '3px' }} size="small" text disabled={!this.props.legendValid} className="hover:bg-blue-200 focus:bg-green-400" />
                      </i>
                  </div>
              </div>
          </div>
        </>
      );
    }
};
