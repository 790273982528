import { useRef,useState } from 'react';
import { Upload } from "./Upload";
import { Comparator } from './Comparator';

import "./style.module.css";

export const Utils = (props) => {
    const {UUID,        
        translator,
        legendTemplate,
        options,
        blocks,
        blockLookUp,
        characteristics,
        characteristicLookUp
    } = props;

    const [fileContent, setFileContent] = useState([]);
    const [visibleUpload, setUploadVisible] = useState<boolean>(true);
    const [visibleComparator, setComparatorVisible] = useState<boolean>(false);
    return (
        <>
            {visibleUpload && <Upload
                UUID={UUID}
                translator={translator}
                legendTemplate={legendTemplate}
                options={options}
                blocks={blocks}
                blockLookUp={blockLookUp}
                characteristics={characteristics}
                characteristicLookUp={characteristicLookUp}
                fileContent={fileContent}
                setFileContent={setFileContent}
                setUploadVisible={setUploadVisible}
                setComparatorVisible={setComparatorVisible}
                />}
            {visibleComparator && <Comparator
                UUID={UUID}
                translator={translator}
                legendTemplate={legendTemplate}
                options={options}
                blocks={blocks}
                blockLookUp={blockLookUp}
                characteristics={characteristics}
                characteristicLookUp={characteristicLookUp}
                fileContent={fileContent}
                setUploadVisible={setUploadVisible}
                setComparatorVisible={setComparatorVisible}
                />}
        </>
    )
}
