import { useState,useRef,useEffect } from "react";
import { Button } from "primereact/button";
import { Controller, useForm } from 'react-hook-form';
import { ListBox, ListBoxChangeEvent } from 'primereact/listbox';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import randomstring from "randomstring";

export const Characteristics = (props) => {
    const{ 
        legend,        
        rerenderTrigger,
        setRerenderTrigger,
        blocks,
        elements,
        characteristics,
        characteristicLookUp,
        LC_Legend,
        LC_Class,
        LC_ClassCharacteristics,
        LC_HorizontalPatterns,
        LC_Strata,        
        LC_Properties,
        LC_Characteristics,
        stratumCharacteristicNumber,
        stratumPropertyNumber,
        activeLCElement,
        setActiveLCElement,
        displayFormElements,
        control,
        errors,
        register,
        handleSubmit,
        getValues,
        setValue,
        watch,
        reset,
        LC_Objectfilter
     } = props;

    const toast = useRef(null);
        
    const updateCharacteristic = (Reference,reference_id,id,element_id,Data)=>{        
        let ReferenceFile = "LC_"+Reference+".current";
        if(Reference === "Characteristics"){
            let newElement = {};
            let characteristicTemplate = characteristics.current["LC_Characteristics"].filter(characteristic => characteristic.characteristic_id === parseInt(Data.objectID1));            
            let element_build = {};
            Object.entries(characteristicTemplate[0].elements).forEach((element)=>{
                if(typeof(Data[element[1]['element_name']]) === "object"){
                    if(Data[element[1]['element_name']].label !== undefined)
                        element_build = {...element_build, [element[1]['element_name']]: Data[element[1]['element_name']].label};
                    else
                        element_build = {...element_build, [element[1]['element_name']]: Data[element[1]['element_name']]};
                }
                else
                    element_build = {...element_build, [element[1]['element_name']]: Data[element[1]['element_name']]};
            });
            newElement = { StratumID: parseInt(element_id), BlockID: parseInt(id), CharacteristicID: parseInt(reference_id), CharacteristicLabel: characteristicTemplate[0].characteristic_label, ...element_build };            
            let Elements = eval(ReferenceFile+'.filter(content => ((content.StratumID !== '+parseInt(element_id)+' || content.BlockID !== '+parseInt(id)+') || (content.StratumID === '+parseInt(element_id)+' && content.BlockID === '+parseInt(id)+' && content.CharacteristicID !== '+parseInt(reference_id)+')))');
            if(Elements.length > 0)
                Elements = [...Elements, newElement];
            else
                Elements = [newElement];
            eval(ReferenceFile+' = Elements');
            toast.current.show({ severity: 'success', summary: 'Characteristic Updated', detail: "Element Characteristic Updated"});
        }
        else{
            if(Reference === "ClassCharacteristics")
                ReferenceFile = ReferenceFile+"["+reference_id+"]";
            Object.keys(Data).forEach((element)=>{
                let appendix = "";
                if(isNaN(id))
                    appendix = "."+id;
                if(Data[element] === null)
                    Data[element] = "";
                if(Data[element] !== undefined){
                    if(typeof(Data[element]) === "object"){
                        if(Data[element].label !== undefined)
                            eval([ReferenceFile]+""+[appendix]+""+"['"+[element]+"']"+"='"+Data[element].label+"'");
                        else
                            eval([ReferenceFile]+""+[appendix]+""+"['"+[element]+"']"+"=["+Data[element][0]+","+Data[element][1]+"]");
                    }
                    else if(typeof(Data[element]) === "number")
                        eval([ReferenceFile]+""+[appendix]+""+"['"+[element]+"']"+"="+Data[element]);
                    else
                        eval([ReferenceFile]+""+[appendix]+""+"['"+[element]+"']"+"='"+Data[element]+"'");
                    toast.current.show({ severity: 'success', summary: 'Characteristic Submitted', detail: [element]+": "+Data[element]});
                }
            })
        }
        setRerenderTrigger(Reference+randomstring.generate(8));
        return(true);
    }

    const submitCharacteristic = (data: any,event) => {
        event.preventDefault();        
        let LCType = (document.getElementById("LCType1") as HTMLInputElement).value;        
        let elementID = (document.getElementById("elementID1") as HTMLInputElement).value;
        let formElementID = (document.getElementById("formElementID1") as HTMLInputElement).value;        
        let objectID = (document.getElementById("objectID1") as HTMLInputElement).value;        
        let formValues = getValues();        
        if(!updateCharacteristic(LCType,objectID,formElementID,elementID,formValues))
            toast.current.show({ severity: 'error', summary: 'Undefined:', detail: 'No Characteristic Type Identified'});
        formReset();
        let tempActiveLCElement = activeLCElement;
        let tempList = list;
        const timer = setTimeout(() => {
            setActiveLCElement(false);
            setList("");
        }, 10);
        const timer2 = setTimeout(() => {
            setActiveLCElement(tempActiveLCElement);
            setList(tempList);
        }, 15);
    };
    const getFormErrorMessage = (name:any) => {
        return errors[name] ? <small className="p-error">This field is required</small> : <small className="p-error">&nbsp;</small>;
    };

    const [displayForm,setDisplayForm] = useState<any>();
    const [hideForm,setHideForm] = useState<boolean>(true);
    const [hideButton,setHideButton] = useState<boolean>(true);
    const [hideList,setHideList] = useState<boolean>(true);
    const [elementID,setElementID] = useState<any>();
    const [formElementID,setFormElementID] = useState<any>();
    const [objectID,setObjectID] = useState<any>();
    const [LCType,setLCType] = useState<any>();
    const [Delete,setDelete] = useState<any>();
    const [resets, setResets] = useState<any>();

    const [list,setList] = useState<any>("");
    const [activeCharacteristic, setActiveCharacteristic] = useState<any>(null);
    useEffect(()=>{
        if(activeLCElement){
            let elementKey = activeLCElement?.split("|");
            let resetter = [];
            setDisplayForm('');
            let activCharacteristic = characteristics.current["LC_Characteristics"]?.filter( characteristic => characteristic.characteristic_id === activeCharacteristic);                        
            if(activCharacteristic !== undefined && activCharacteristic[0] !== undefined){
                setDisplayForm([<p style={{textAlign: 'center' }}>{activCharacteristic[0]['characteristic_label']}:</p>,displayFormElements(activCharacteristic[0].elements)]);
                setHideButton(false);
                activCharacteristic[0].elements.map((element)=>{
                    let prevValue = undefined;
                    if(LC_Characteristics.current.length > 0){
                        let relevantCharacteristic = LC_Characteristics.current.filter(characteristics => characteristics.StratumID === parseInt(elementKey[elementKey.length-2]) && characteristics.BlockID === parseInt(elementKey[elementKey.length-1]) && characteristics.CharacteristicID === activeCharacteristic);
                        if(relevantCharacteristic[0] !== undefined)
                            prevValue = relevantCharacteristic[0][element['element_name']];
                    }
                    if(typeof(prevValue) === "undefined")
                        resetter.push('setValue("'+element['element_name']+'", "")');
                    else if(typeof(prevValue) === "object")
                        resetter.push('setValue("'+element['element_name']+'", ['+prevValue[0]+','+prevValue[1]+'])');
                    else if(typeof(prevValue) === "number")
                        resetter.push('setValue("'+element['element_name']+'", '+prevValue+')');
                    else
                        resetter.push('setValue("'+element['element_name']+'", "'+prevValue+'")');
                });
                resetter.push('setValue("objectID1", '+activeCharacteristic+')');
                resetter.push('setValue("formElementID1", "'+elementKey[elementKey.length-1]+'")');
                setFormElementID(elementKey[elementKey.length-1]);
                setObjectID(activeCharacteristic);
            }
            setResets(resetter);
        }        
    },[activeCharacteristic,list]);
    
    useEffect(()=>{
        if(activeLCElement){
            let elementKey = activeLCElement?.split("|");
            let resetter = [];
            switch(elementKey.length) {
                case 4:
                    reset();
                    setHideForm(false);
                    setHideList(true);
                    setHideButton(false);
                    setLCType('ClassCharacteristics');
                    setDelete(null);
                    let ClassCharacteristicType = legend["LC_ClassCharacteristics"].filter(characteristic => characteristic.characteristic_name === elementKey[elementKey.length-1]);                    
                    setDisplayForm('');
                    setDisplayForm([<p style={{textAlign: 'center' }}>{ClassCharacteristicType[0]['characteristic_label']}:</p>,displayFormElements(ClassCharacteristicType[0]["elements"])]);                    
                    let ClassID = elementKey[2];
                    ClassCharacteristicType[0]["elements"].map((element)=>{
                        let prevValue = LC_ClassCharacteristics.current[ClassID][elementKey[elementKey.length-1]][element['element_name']];
                        if(prevValue === undefined)
                            prevValue = null;                        
                        if(typeof(prevValue) === "undefined" || prevValue === null || prevValue === "")
                            resetter.push('setValue("'+element['element_name']+'", '+null+')');
                        else if(typeof(prevValue) === "object")
                            resetter.push('setValue("'+element['element_name']+'", ['+prevValue[0]+','+prevValue[1]+'])');
                        else if(typeof(prevValue) === "number")
                            resetter.push('setValue("'+element['element_name']+'", '+prevValue+')');
                        else
                            resetter.push('setValue("'+element['element_name']+'", "'+prevValue+'")');
                    });
                    resetter.push('setValue("objectID1", "'+elementKey[2]+'")');
                    resetter.push('setValue("formElementID1", "'+elementKey[elementKey.length-1]+'")');
                    resetter.push('setValue("elementID1", "'+elementKey[elementKey.length-1]+'")');
                    setElementID(elementKey[elementKey.length-1]);
                    setFormElementID(elementKey[elementKey.length-1]);
                    setObjectID(elementKey[2]);
                    setResets(resetter);
                    break;
                case 7:
                    reset();
                    setHideForm(false);
                    setHideList(false);
                    setHideButton(true);
                    setLCType('Characteristics');
                    setDelete(<Button id="Delete" name="Delete" label="Delete" type="button" icon="pi pi-trash"  style={{ width: 'fit-contents', verticalAlign: 'center', fontSize: 'x-small', height: 20, backgroundColor: '#aaa', color: '#fff', borderRadius: 'var(--border-radius)' }} className="flex align-self-center" size="small" raised onClick={()=>setConfirmCharacteristicDelete(true)} />);
                    setActiveCharacteristic(null);
                    let subDisplayCharacteristics = [];
                    Object.keys(characteristicLookUp.current).forEach((key0) => { 
                        let _1_blocks = characteristicLookUp.current[key0].filter( selectedblock => selectedblock.block_id === parseInt(elementKey[elementKey.length-1]));            
                        Object.keys(_1_blocks).forEach((key1) => {
                            let _characteristics = characteristics.current["LC_Characteristics"]?.filter( characteristics => characteristics.characteristic_id === _1_blocks[key1]["characteristic_id"] );                
                            Object.keys(_characteristics).forEach((key2) => {
                                subDisplayCharacteristics = ([...subDisplayCharacteristics, { label: _characteristics[key2]["characteristic_label"], name: _characteristics[key2]["characteristic_name"], value: _characteristics[key2]["characteristic_id"] }]);
                            });
                        });
                    });                                        
                    setList(<><p className="text-xxs" style={{ textAlign: 'center' }}>Select Characteristic below</p><ListBox listStyle={{ maxHeight: '150px' }} value={activeCharacteristic} onChange={(e: ListBoxChangeEvent) => setActiveCharacteristic(e.value)} options={subDisplayCharacteristics} optionLabel="label" className="w-full text-xs" listClassName="text-xs" filter /></>);
                    resetter.push('setValue("objectID1", '+elementKey[elementKey.length-1]+')');
                    resetter.push('setValue("formElementID1", "'+activeCharacteristic+'")');
                    resetter.push('setValue("elementID1", "'+elementKey[elementKey.length-2]+'")');
                    setElementID(elementKey[elementKey.length-2]);
                    setFormElementID(activeCharacteristic);
                    setObjectID(elementKey[elementKey.length-1]);
                    setResets(resetter);
                    break;
                default:
                    setHideForm(true);setHideButton(true);setDelete(null);
            }
        }
        /*const timer = setTimeout(() => {
            reset();
        }, 500);*/
    },[activeLCElement,rerenderTrigger]);

    const formReset = ()=>{
        resets?.map((formElement)=>{
            const timer = setTimeout(() => {                
                eval(formElement);
            }, 20);
        });
    }

    const [confirmCharacteristicDelete, setConfirmCharacteristicDelete] = useState<boolean>(false);   
    const acceptCharacteristicDelete = (elementID,formElementID,objectID) => {
        let deletd_Characteristic = LC_Characteristics.current.filter(characteristics => characteristics.StratumID === parseInt(elementID) && characteristics.BlockID === parseInt(formElementID) && characteristics.CharacteristicID === parseInt(objectID));        
        if(deletd_Characteristic[0] !== undefined ){
            deleteCharacteristic(elementID,formElementID,objectID);
            toast.current?.show({ severity: 'warn', summary: 'Confirmed', detail: 'Element '+deletd_Characteristic[0].CharacteristicLabel+' deleted!', life: 3000 });
        }
        else
            toast.current?.show({ severity: 'error', summary: 'Failed', detail: 'Characteristic is not set, cannot delete!', life: 3000 });
    }
    const reject = () => {
        toast.current?.show({ severity: 'info', summary: 'Cancelled', detail: 'Action Cancelled', life: 3000 });
    }

    const deleteCharacteristic = (StratumID,BlockID,CharacteristicID)=>{
        let undeletd_characteristics = LC_Characteristics.current.filter(characteristics => ((characteristics.StratumID !== parseInt(StratumID) || characteristics.BlockID !== parseInt(BlockID)) || (characteristics.StratumID === parseInt(StratumID) && characteristics.BlockID === parseInt(BlockID) && characteristics.CharacteristicID !== parseInt(CharacteristicID))));        
        LC_Characteristics.current = [...(undeletd_characteristics || [])];
        stratumCharacteristicNumber.current = undeletd_characteristics.length+1;
        setRerenderTrigger('Deleted Characteristic'+stratumCharacteristicNumber.current);
        setActiveLCElement(null);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activeCharacteristic]);
    
    return (
        <>
        {activeLCElement &&
            <div className="flex flex-column gap-1 text-xs p-2 m-0 w-full overflow-auto">
                <Toast ref={toast} />
                <ConfirmDialog visible={confirmCharacteristicDelete} onHide={() => setConfirmCharacteristicDelete(false)} message="Are you sure you want to delete the Characteristic?" header="Delete Characteristic Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={()=>acceptCharacteristicDelete(elementID,formElementID,objectID)} reject={reject} />
                <form id="Characteristics" name="Characteristics" onSubmit={handleSubmit(submitCharacteristic)} className="flex flex-column justify-contents-center">
                    <Controller
                    name="LCType1"                                 
                    control={control}
                    render={({ field, fieldState }) => (
                        <input key={field.name} type="hidden" id={field.name} name={field.name} value={LCType} />
                        )}
                    />
                    <Controller
                    name="elementID1"
                    control={control}
                    render={({ field, fieldState }) => (
                        <input key={field.name} type="hidden" id={field.name} name={field.name} value={elementID} />
                        )}
                    />
                    <Controller
                    name="formElementID1"
                    control={control}
                    render={({ field, fieldState }) => (
                        <input key={field.name} type="hidden" id={field.name} name={field.name} value={formElementID} />
                        )}
                    />
                    <Controller
                    name="objectID1"
                    control={control}
                    render={({ field, fieldState }) => (
                        <input key={field.name} type="hidden" id={field.name} name={field.name} value={objectID} />
                        )}
                    />                    
                    {!hideForm && <>                        
                        {displayForm}
                        {formReset()}
                        {!hideButton && 
                        <div className="flex flex-row overflow-auto">
                            <div className="flex align-items-center justify-content-center" style={{ width: '80%' }}>
                                <Button id="Characteristics" name="Characteristics" label="Save Characteristics" type="submit" icon="pi pi-check"  style={{ width: '60%', alignSelf: 'center', verticalAlign: 'center', fontSize: 'x-small', height: 20, backgroundColor: 'var(--blue-100)', color: 'var(--error-100)', borderRadius: 'var(--border-radius)' }} className="flex align-self-center" size="small" raised />
                            </div>
                            <div className="flex right-0" style={{ width: '20%' }}>                            
                                {Delete}
                            </div>
                        </div>}
                        {!hideList && list}
                    </>}             
                </form>
            </div>
        }        
        </>
    );
}
