import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";

const TOUR_STEPS = [
  {
    target: ".guide-menu",
    content: "Menu bar with all functions.",
    disableBeacon: true // This makes the tour to start automatically without clicking
  },
  {
    target: ".guide-subMenu",
    content: "Short cut functions."    
  },
  {
    target: ".guide-LCMLElements",
    content: "The full list of LCML Elements.  Double click on an element to add to your legend."
  },
  {
    target: ".guide-myLegend",
    content: "Tree representation of your Legend.  Click on the items to edit."
  },
  {
    target: ".guide-legendDiagram",
    content: "Visual representation of your Legend.  Click on the items to edit properties and characteristics below."    
  },
  {
    target: ".guide-properties",
    content: "Manage your Legend's Properties here."
  },
  {
    target: ".guide-characteristics",
    content: "Manage your Legend's Element Characteristics here."
  },
  {
    target: ".guide-legendTabs",
    content: "Select legend or class diagrams here."
  },
  {
    target: ".guide-newLegend",
    content: "Create a new Legend."
  },
  {
    target: ".guide-importLegend",
    content: "Import a Legend."
  },
  {
    target: ".guide-exportLegend",
    content: "Export your Legend."
  },
  {
    target: ".guide-validateLegend",
    content: "Validate your Legend."
  },
  {
    target: ".guide-legendWizard",
    content: "Click here to build your Legend step by step."
  },
  {
    target: ".guide-docs",
    content: "LChS Documentation."
  }
];

const INITIAL_STATE = {
  key: new Date(), // This field makes the tour to re-render when we restart the tour
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS
};

// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date()
      };
    default:
      return state;
  }
};

// Tour component
export const Guide = (props) => {
  const { setGuideVisible } = props;
  // Tour state is the state which control the JoyRide component
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    // Auto start the tour if the tour is not viewed before
    if (!localStorage.getItem("tour")) {
      dispatch({ type: "START" });
    }    
  }, []);

  // Set once tour is viewed, skipped or closed
  const setTourViewed = () => {
    // localStorage.setItem("tour", "1");
    setGuideVisible(false);
  };

  const callback = data => {
    const { action, index, type, status } = data;

    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      setTourViewed();
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
      });
    }
  };

  const startTour = () => {
    // Start the tour manually
    dispatch({ type: "RESTART" });
  };

  return (
      <JoyRide
        {...tourState}
        callback={callback}
        showSkipButton={true}
        styles={{
          tooltipContainer: {
            textAlign: "left"
          },
          buttonBack: {
            marginRight: 10
          }
        }}
        locale={{
          last: "End tour"
        }}
      />
  );
};


  