import { useState,useRef,useEffect } from 'react';
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectEvent, FileUploadHandlerEvent, ItemTemplateOptions } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Card } from 'primereact/card';
import XMLViewer from 'react-xml-viewer';
import { parseString } from 'xml2js';
import * as processors from 'xml2js/lib/processors';
import Papa from 'papaparse';

import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';

import { searchObjKeyVal } from "../../App";
import { fuzzySearchObjKeyVal } from "../../App";

export const Upload = (props) => {
    const {UUID,
        translator,
        legendTemplate,
        options,        
        blocks,
        blockLookUp,
        characteristics,
        characteristicLookUp,
        fileContent,
        setFileContent,
        setUploadVisible,
        setComparatorVisible
        } = props;

    const toast = useRef<Toast>(null);
    
    const [fileDisplay,setFileDisplay] = useState([]);
    const handleFileChange = (event) => {
        let details = [];        
        event.files.map((file)=>{            
            if(file){
                const reader = new FileReader();
                let header = null;
                reader.onload = (e) => {
                    let LegendType = file.name.split(".").pop();
                    if(LegendType === "csv"){
                        header = String(e.target.result).split("\r\n")[0];                        
                        details.push("<file>"+file.name+"</file><data_fields>"+header+"</data_fields>");
                    }
                    else if(LegendType === "lchs" || LegendType === "lccs" || LegendType === "xml")                        
                        details.push("<file>"+file.name+"</file>"+e.target.result);                    
                    else                        
                        details.push("<file>"+file.name+"</file><error>LChS only supports LChS, LCCS and CSV legends at the moment.</error>");                    
                    setFileDisplay(details);
                };
                reader.readAsText(file);
                setTotalSize(file.size);
            }
        });
    }
    
    const Legends = useRef([]);    
    const importLegend = (upload) => {        
        upload.files.map((file)=>{
            if(file){
                const reader = new FileReader();
                let header = null;

                let translation = null;
                let LCT_Legend = {};
                let LCT_Class = [];
                let classesNumber = 1;
                let LCT_ClassCharacteristics = {};
                let LCT_HorizontalPatterns = [];
                let horizontalPatternNumber = 1;
                let LCT_Strata = [];
                let strataNumber = 1;
                let LCT_Properties = [];
                let stratumPropertyNumber = 1;
                let LCT_Characteristics = [];
                let stratumCharacteristicNumber = 1;

                reader.onload = (e) => {
                    let LegendType = file.name.split(".").pop();
                    translation = translator[LegendType];
                    if(LegendType === "csv"){                        
                        LCT_Legend = {id: 1, legend_name: file.name, legend_description: 'LChS CSV Translator', legend_author: file.name};
                        LCT_Class = [];
                        LCT_ClassCharacteristics = {};
                        LCT_HorizontalPatterns = [];
                        horizontalPatternNumber = 1;
                        LCT_Strata = [];
                        strataNumber = 1;
                        LCT_Properties = [];
                        LCT_Characteristics = [];
                        let importedClasses = Papa.parse(e.target.result, {header: true});

                        let header_translation = {};
                        Object.keys(importedClasses.data[0]).map((header)=>{
                            let header_reference = header;
                            let TranslatedHeader = searchObjKeyVal(translation["LC_ClassElements"],'Translation',header);
                            if(TranslatedHeader.length > 0)
                                header_reference = TranslatedHeader[0]['LChS_EquivalentElement'];
                            if(header_reference !== header)
                                header_translation = {...header_translation, [header_reference]: header};
                        });

                        Object.values(importedClasses['data']).forEach((clss)=>{
                            if(clss['ID']){
                                Object.entries(header_translation).forEach((header)=>{
                                    clss[header[0]] = clss[header[1] as any];
                                })
                                if(clss['ID'] && clss['Class Name'] && clss['Elements']){
                                    LCT_Class = [...(LCT_Class || []), {legend_id: 1, class_id: parseInt(clss['ID']), class_name: clss['Class Name'], class_description: clss['Class Description'], class_map_code: clss['Class Code'], class_color_code: clss['Color Code(Hex)'] }];
                                    LCT_HorizontalPatterns = [...(LCT_HorizontalPatterns || []), { class_id: parseInt(clss['ID']), horizontal_pattern_id: horizontalPatternNumber, name: "Horizontal Pattern "+horizontalPatternNumber, description: "Class Horizontal Pattern", cover: [0,100], occurrence: [0,100], type: null  }];
                                    LCT_Strata = [...(LCT_Strata || []), { HPID: horizontalPatternNumber, stratumID: strataNumber, name: "Stratum "+strataNumber, description: "Horizontal Pattern "+horizontalPatternNumber+" Stratum", presence_type: "Mandatory", on_top: null }]; 
                                    let elements = clss['Elements'].split(';');
                                    let refBlock = null;
                                    let BlockID = null;
                                    if(elements.length > 0){
                                        elements.map((element)=>{
                                            element = element.trim();
                                            if(element != ""){
                                                let TranslatedElement = fuzzySearchObjKeyVal(translation["LC_Blocks"],'Translation',element);                                
                                                if(TranslatedElement.length > 0)
                                                    element = TranslatedElement[0]['LChS_EquivalentElement'];
                                                if(element !== ""){
                                                    refBlock = blocks.current["LC_Block"]?.filter(block => block.block_reference === element);
                                                    if(refBlock[0] !== undefined){
                                                        BlockID = refBlock[0]['block_id'];
                                                        LCT_Properties = [...(LCT_Properties || []), { StratumID: strataNumber, BlockID: parseInt(BlockID) }];
                                                    }
                                                }
                                            }
                                        });
                                    }                
                                    horizontalPatternNumber++;
                                    strataNumber++;
                                }
                            }
                        });
                        Legends.current.push({[file.name]: {LCT_Legend: LCT_Legend, LCT_Class: LCT_Class, LCT_ClassCharacteristics: LCT_ClassCharacteristics, LCT_HorizontalPatterns: LCT_HorizontalPatterns, LCT_Strata: LCT_Strata, LCT_Properties: LCT_Properties}});
                    }
                    else if(LegendType === "lchs" || LegendType === "lccs" || LegendType === "xml"){
                        LCT_Legend = {id: 1, legend_name: file.name, legend_description: 'LChS CSV Translator', legend_author: file.name};
                        LCT_Class = [];
                        LCT_ClassCharacteristics = {};
                        parseString(e.target.result, {trim: true,explicitArray: false,attrValueProcessors: [processors.parseBooleans, processors.parseNumbers],valueProcessors: [processors.parseBooleans, processors.parseNumbers]}, (err, result) => {                            
                            if (err) {
                                console.error('Error parsing XML:', err);
                                toast.current.show({ severity: 'error', summary: 'Read Error', detail: file.name+' has a read fault and will not be processed.', life: 5000 });
                            } else {                                
                                if(LegendType === "lchs"){
                                    let ClassXteristic = {};
                                    Object.entries(result['LC_Legend']['objects']['LC_ClassCharacteristics']).forEach((Xteristic)=>{
                                        ClassXteristic = {...ClassXteristic, [String(Xteristic[0].replace("_",""))]:Xteristic[1]};                        
                                    });
                                    LCT_Legend = result['LC_Legend']['$'];
                                    LCT_Class = result['LC_Legend']['objects']['LC_Class'];
                                    if(!Array.isArray(LCT_Class))
                                        LCT_Class = [LCT_Class];
                                    LCT_ClassCharacteristics = ClassXteristic;
                                    LCT_HorizontalPatterns = result['LC_Legend']['objects']['LC_HorizontalPatterns'];
                                    if(!Array.isArray(LCT_HorizontalPatterns))
                                        LCT_HorizontalPatterns = [LCT_HorizontalPatterns];
                                    horizontalPatternNumber = LCT_HorizontalPatterns.length+1;
                                    LCT_Strata = result['LC_Legend']['objects']['LC_Strata'];
                                    if(!Array.isArray(LCT_Strata))
                                        LCT_Strata = [LCT_Strata];
                                    strataNumber = LCT_Strata.length+1;
                                    LCT_Properties = result['LC_Legend']['objects']['LC_Properties'];
                                    if(!Array.isArray(LCT_Properties))
                                        LCT_Properties = [LCT_Properties];
                                    stratumPropertyNumber = LCT_Properties.length+1;
                                    LCT_Characteristics = result['LC_Legend']['objects']['LC_Characteristics'];
                                    if(LCT_Characteristics === undefined)
                                        LCT_Characteristics = [];
                                    else if(!Array.isArray(LCT_Characteristics))
                                        LCT_Characteristics = [LCT_Characteristics];
                                    stratumCharacteristicNumber = LCT_Characteristics.length+1;
                                } else {
                                    LCT_Legend = {id:1, legend_name: file.name, legend_description: 'LCHS LCCS Translator', legend_author: file.name};
                                    LCT_Class = [{legend_id: 1, class_id: 1, class_name: 'Class 1', class_description: 'The land characterization class', class_map_code: 'LCR1', class_color_code: 'FF0000'}];
                                    classesNumber = 1;
                                    LCT_ClassCharacteristics = {};
                                    LCT_HorizontalPatterns = [];
                                    horizontalPatternNumber = 1;
                                    LCT_Strata = [];
                                    strataNumber = 1;
                                    LCT_Properties = [];
                                    stratumPropertyNumber = 1;
                                    LCT_Characteristics = [];
                                    stratumCharacteristicNumber = 1;                                    
                                    Object.entries(translation).forEach((translation_element)=>{                                        
                                        let translation_map = translation_element[1] as any;
                                        let MaxStratumID = 0; let MaxHPID = 0; let MaxClassID = 0;
                                        translation_map.forEach((translation_map)=>{
                                            if(translation_element[0] === "LC_Legend")
                                                eval("LCT_Legend"+"['"+translation_map.LChS_EquivalentElement+"']='"+eval("result"+translation_map.Translation)+"'");
                                            else if(translation_element[0] === "LC_Class"){
                                                let classTranslation = eval("result"+translation_map.Translation);
                                                let classes = [];
                                                if(!Array.isArray(classTranslation))
                                                    classTranslation = [classTranslation];
                                                classTranslation.map((clss)=>{
                                                    let buildClass = {};
                                                    let legend_id = null;
                                                    let class_id = null;
                                                    Object.entries(clss).forEach((clss_details)=>{
                                                        if(clss_details[0] === "$"){
                                                            legend_id = 1;
                                                            class_id = parseInt(clss_details[1]['id'], 16);
                                                            if(MaxClassID <= class_id)
                                                                MaxClassID = class_id;
                                                            buildClass = {...buildClass, legend_id: legend_id, class_id: class_id};
                                                        }
                                                        else if(clss_details[0] !== "$" && clss_details[0] !== "elements"){
                                                            let transClass = searchObjKeyVal(translation['LC_ClassElements'],'Translation',clss_details[0]);
                                                            if(typeof(clss_details[1]) === "object"){
                                                                Object.entries(clss_details[1]).forEach((clss_obj_detail)=>{
                                                                    buildClass = {...buildClass, [transClass[0]['LChS_EquivalentElement']]: [parseInt(clss_obj_detail[1]['min']),parseInt(clss_obj_detail[1]['max'])]};
                                                                });
                                                            }
                                                            else
                                                                buildClass = {...buildClass, [transClass[0]['LChS_EquivalentElement']]: clss_details[1]};
                                                        }
                                                        else if(clss_details[0] === "elements"){
                                                            let ClassCharacteristics = clss_details[1]['LC_Characteristic'];  //create array for processing;
                                                            let Charact = {};
                                                            if(ClassCharacteristics !== undefined){
                                                                if(!Array.isArray(ClassCharacteristics))
                                                                    ClassCharacteristics = [ClassCharacteristics];
                                                                ClassCharacteristics.map((ClassCharacteristic)=>{
                                                                    let CharRef = ClassCharacteristic['$']['xsi:type'];
                                                                    let TranslatedCharRef = searchObjKeyVal(translation["LC_ClassCharacteristics"],'Translation',CharRef);
                                                                    if(TranslatedCharRef[0] !== undefined)
                                                                        CharRef = TranslatedCharRef[0]['LChS_EquivalentElement'];
                                                                    let refCharacteristic = searchObjKeyVal(legendTemplate.current["LC_ClassCharacteristics"],"characteristic_reference",CharRef);
                                                                    if(refCharacteristic != undefined){
                                                                        Object.entries(ClassCharacteristic).forEach((ClassCharacteristic_details)=>{
                                                                            if(ClassCharacteristic_details[0] !== "$"){
                                                                                let transCharacteristic = searchObjKeyVal(translation['LC_ClassCharacteristicElements'],'Translation',`['`+ClassCharacteristic['$']['xsi:type']+`']['`+ClassCharacteristic_details[0]+`']`);
                                                                                if(typeof(ClassCharacteristic_details[1]) === "object"){
                                                                                    Object.entries(ClassCharacteristic_details[1]).forEach((ClassCharacteristic_obj_detail)=>{
                                                                                        Charact = {...Charact, [refCharacteristic[0]['characteristic_name']]: {...Charact[refCharacteristic[0]['characteristic_name']], [transCharacteristic[0]['LChS_EquivalentElement']]:[parseInt(ClassCharacteristic_obj_detail[1]['min']),parseInt(ClassCharacteristic_obj_detail[1]['max'])]}};
                                                                                    });
                                                                                }
                                                                                else
                                                                                    Charact = {...Charact, [refCharacteristic[0]['characteristic_name']]: {...Charact[refCharacteristic[0]['characteristic_name']], [transCharacteristic[0]['LChS_EquivalentElement']]:ClassCharacteristic_details[1]}};
                                                                            }
                                                                        });
                                                                    }                                                                    
                                                                });
                                                            }
                                                            LCT_ClassCharacteristics[class_id] = Charact;
                                                            
                                                            let patterns = clss_details[1]['LC_HorizontalPattern'];  //create array for processing
                                                            let HPs = [];
                                                            let Strata = [];
                                                            let Properties = [];
                                                            let Characteristics = [];
                                                            if(!Array.isArray(patterns))
                                                                patterns = [patterns];
                                                            patterns.map((pattern)=>{
                                                                let PatternRef = pattern['$']['xsi:type'];
                                                                let buildHP = {};
                                                                let HPID = null;
                                                                Object.entries(pattern).forEach((pattern_details)=>{
                                                                    if(pattern_details[0] === "$"){
                                                                        HPID = parseInt(pattern_details[1]['id'], 16);
                                                                        if(MaxHPID <= HPID)
                                                                            MaxHPID = HPID+1;
                                                                        buildHP = {...buildHP, class_id: class_id, horizontal_pattern_id: HPID};
                                                                    }
                                                                    else if(pattern_details[0] !== "$" && pattern_details[0] !== "elements"){
                                                                        let transPattern = searchObjKeyVal(translation['LC_HorizontalPatternElements'],'Translation',pattern_details[0]);
                                                                        if(typeof(pattern_details[1]) === "object"){
                                                                            Object.entries(pattern_details[1]).forEach((pattern_obj_detail)=>{
                                                                                buildHP = {...buildHP, [transPattern[0]['LChS_EquivalentElement']]: [parseInt(pattern_obj_detail[1]['min']),parseInt(pattern_obj_detail[1]['max'])]};
                                                                            });
                                                                        }
                                                                        else
                                                                            buildHP = {...buildHP, [transPattern[0]['LChS_EquivalentElement']]: pattern_details[1]};
                                                                    }
                                                                    else if(pattern_details[0] === "elements"){
                                                                        let LC_Strata = pattern_details[1]['LC_Stratum']; //create array for processing
                                                                        if(!Array.isArray(LC_Strata))
                                                                            LC_Strata = [LC_Strata];
                                                                        LC_Strata.map((item)=>{
                                                                            let buildStrata = {};
                                                                            let StratumID = null;
                                                                            Object.entries(item).forEach((stratum)=>{
                                                                                if(stratum[0] === "$"){
                                                                                    StratumID = parseInt(stratum[1]['id'], 16);
                                                                                    if(MaxStratumID <= StratumID)
                                                                                        MaxStratumID = StratumID+1;
                                                                                    buildStrata = {...buildStrata, HPID: HPID, stratumID: StratumID};
                                                                                }
                                                                                else if(stratum[0] !== "$" && stratum[0] !== "elements"){
                                                                                    let transStratum = searchObjKeyVal(translation['LC_StratumElements'],'Translation',stratum[0]);
                                                                                    if(typeof(stratum[1]) === "object"){
                                                                                        Object.entries(stratum[1]).forEach((stratum_obj_detail)=>{
                                                                                            buildStrata = {...buildStrata, [transStratum[0]['LChS_EquivalentElement']]: [parseInt(stratum_obj_detail[1]['min']),parseInt(stratum_obj_detail[1]['max'])]};
                                                                                        });
                                                                                    }
                                                                                    else
                                                                                        buildStrata = {...buildStrata, [transStratum[0]['LChS_EquivalentElement']]: stratum[1]};
                                                                                }
                                                                                else if(stratum[0] === "elements"){
                                                                                    let LC_LandCoverElement = stratum[1]['LC_LandCoverElement']; //create array for processing
                                                                                    if(!Array.isArray(LC_LandCoverElement))
                                                                                        LC_LandCoverElement = [LC_LandCoverElement];
                                                                                    LC_LandCoverElement.map((LC_element)=>{
                                                                                        let buildProperties = {};
                                                                                        let BlockID = null;
                                                                                        let refBlock = [];
                                                                                        let block_source = "";
                                                                                        Object.entries(LC_element).forEach((property)=>{
                                                                                            refBlock = [];
                                                                                            if(property[0] === "$"){
                                                                                                let block_reference = property[1]['xsi:type'];
                                                                                                let TranslatedBlock = searchObjKeyVal(translation["LC_Blocks"],'Translation',block_reference);
                                                                                                if(TranslatedBlock.length > 0)
                                                                                                    block_reference = TranslatedBlock[0]['LChS_EquivalentElement'];
                                                                                                refBlock = blocks.current["LC_Block"]?.filter(block => block.block_reference === block_reference);
                                                                                                if(refBlock[0] !== undefined){
                                                                                                    BlockID = refBlock[0]['block_id'];
                                                                                                    buildProperties = {...buildProperties, StratumID: StratumID, BlockID: BlockID};
                                                                                                }                                                                                                
                                                                                            }
                                                                                            else if(property[0] !== "$" && property[0] !== "elements"){
                                                                                                let transProperties = searchObjKeyVal(translation['LC_BlockElements'],'Source_ElementDefinition',block_source);                                                                                    
                                                                                                let transProperty = [];
                                                                                                transProperty = searchObjKeyVal(transProperties,'Translation',property[0]);
                                                                                                if(transProperty[0] !== undefined)
                                                                                                    property[0] = transProperty[0]['LChS_EquivalentElement'];
                                                                                                if(typeof(property[1]) === "object"){
                                                                                                    Object.entries(property[1]).forEach((detail)=>{
                                                                                                        buildProperties = {...buildProperties, [property[0]]: [parseInt(detail[1]['min']),parseInt(detail[1]['max'])]};
                                                                                                    });
                                                                                                }
                                                                                                else
                                                                                                    buildProperties = {...buildProperties, [property[0]]: property[1]};
                                                                                            }
                                                                                            else if(property[0] === "elements"){
                                                                                                let LC_Characteristic = property[1]['LC_Characteristic']; //create array for processing
                                                                                                if(!Array.isArray(LC_Characteristic))
                                                                                                    LC_Characteristic = [LC_Characteristic];
                                                                                                LC_Characteristic.map((LC_Characteristic_item)=>{
                                                                                                    let buildCharacteristics = {};
                                                                                                    let CharacteristicID = null;
                                                                                                    let CharacteristicRef = null;
                                                                                                    let refCharacteristic = [];
                                                                                                    Object.entries(LC_Characteristic_item).forEach((characteristic)=>{
                                                                                                        refCharacteristic = [];
                                                                                                        if(characteristic[0] === "$"){
                                                                                                            let XterRef = characteristic[1]['xsi:type'];
                                                                                                            let TranslatedXterRef = searchObjKeyVal(translation["LC_Blocks"],'Translation',XterRef);
                                                                                                            if(TranslatedXterRef[0] !== undefined)
                                                                                                                XterRef = TranslatedXterRef[0]['LChS_EquivalentElement'];
                                                                                                            refCharacteristic = characteristics.current["LC_Characteristics"]?.filter(xteristic => xteristic.characteristic_reference === XterRef);
                                                                                                            if(refCharacteristic[0] !== undefined){
                                                                                                                CharacteristicID = refCharacteristic[0]['characteristic_id'];
                                                                                                                CharacteristicRef = characteristic[1]['xsi:type'];
                                                                                                                buildCharacteristics = {...buildCharacteristics, StratumID: StratumID, BlockID: BlockID, CharacteristicID: CharacteristicID};
                                                                                                            }                                                                                                            
                                                                                                        }
                                                                                                        else if(characteristic[0] !== "$" && characteristic[0] !== "elements"){
                                                                                                            let transCharacteristics = searchObjKeyVal(translation['LC_CharacteristicElements'],'Source_ElementDefinition',CharacteristicRef);
                                                                                                            let transCharacteristic = [];                                                                                                            
                                                                                                            transCharacteristic = searchObjKeyVal(transCharacteristics,'Translation',characteristic[0]);
                                                                                                            if(transCharacteristic[0] !== undefined)
                                                                                                                characteristic[0] = transCharacteristic[0]['LChS_EquivalentElement'];
                                                                                                            if(typeof(characteristic[1]) === "object"){
                                                                                                                Object.entries(characteristic[1]).forEach((characteristic_obj_detail)=>{
                                                                                                                    buildCharacteristics = {...buildCharacteristics, [characteristic[0]]: [parseInt(characteristic_obj_detail[1]['min']),parseInt(characteristic_obj_detail[1]['max'])]};
                                                                                                                });
                                                                                                            }
                                                                                                            else
                                                                                                                buildCharacteristics = {...buildCharacteristics, [characteristic[0]]: characteristic[1]};
                                                                                                        }
                                                                                                        else if(characteristic[0] === "elements"){
                                                                                                            let LC_SubCharacteristic = characteristic[1]['LC_Characteristic']; //create array for processing
                                                                                                            if(!Array.isArray(LC_SubCharacteristic))
                                                                                                                LC_SubCharacteristic = [LC_SubCharacteristic];
                                                                                                            LC_SubCharacteristic.map((LC_SubCharacteristic_SubItem)=>{
                                                                                                                let buildSubCharacteristics = {};
                                                                                                                let SubCharacteristicID = null;
                                                                                                                let SubCharacteristicRef = null;
                                                                                                                let refSubCharacteristic = [];
                                                                                                                Object.entries(LC_SubCharacteristic_SubItem).forEach((SubCharacteristic)=>{
                                                                                                                    refSubCharacteristic = [];
                                                                                                                    if(SubCharacteristic[0] === "$"){
                                                                                                                        let SubXterRef = SubCharacteristic[1]['xsi:type'];
                                                                                                                        let TranslatedSubXterRef = searchObjKeyVal(translation["LC_Blocks"],'Translation',SubXterRef);
                                                                                                                        if(TranslatedSubXterRef[0] !== undefined)
                                                                                                                            SubXterRef = TranslatedSubXterRef[0]['LChS_EquivalentElement'];
                                                                                                                        refSubCharacteristic = characteristics.current["LC_Characteristics"]?.filter(xteristic => xteristic.characteristic_reference === SubXterRef);
                                                                                                                        if(refSubCharacteristic[0] !== undefined){
                                                                                                                            SubCharacteristicID = refSubCharacteristic[0]['characteristic_id'];
                                                                                                                            SubCharacteristicRef = SubCharacteristic[1]['xsi:type'];
                                                                                                                            buildSubCharacteristics = {...buildSubCharacteristics, StratumID: StratumID, BlockID: BlockID, CharacteristicID: SubCharacteristicID};
                                                                                                                        }                                                                                                                        
                                                                                                                    }
                                                                                                                    else if(SubCharacteristic[0] !== "$" && SubCharacteristic[0] !== "elements"){
                                                                                                                        let transSubCharacteristics = searchObjKeyVal(translation['LC_CharacteristicElements'],'Source_ElementDefinition',SubCharacteristicRef);
                                                                                                                        let transSubCharacteristic = [];
                                                                                                                        transSubCharacteristic = searchObjKeyVal(transSubCharacteristics,'Translation',SubCharacteristic[0]);
                                                                                                                        if(transSubCharacteristic[0] !== undefined)
                                                                                                                            SubCharacteristic[0] = transSubCharacteristic[0]['LChS_EquivalentElement'];
                                                                                                                        if(typeof(SubCharacteristic[1]) === "object"){
                                                                                                                            Object.entries(SubCharacteristic[1]).forEach((SubCharacteristic_obj_detail)=>{
                                                                                                                                buildSubCharacteristics = {...buildSubCharacteristics, [SubCharacteristic[0]]: [parseInt(SubCharacteristic_obj_detail[1]['min']),parseInt(SubCharacteristic_obj_detail[1]['max'])]};
                                                                                                                            });
                                                                                                                        }
                                                                                                                        else
                                                                                                                            buildSubCharacteristics = {...buildSubCharacteristics, [SubCharacteristic[0]]: SubCharacteristic[1]};
                                                                                                                    }
                                                                                                                });
                                                                                                                Characteristics = [...Characteristics, buildSubCharacteristics];
                                                                                                            });
                                                                                                        }
                                                                                                    });
                                                                                                    Characteristics = [...Characteristics, buildCharacteristics];
                                                                                                });
                                                                                            }
                                                                                        });
                                                                                        Properties = [...Properties, buildProperties];
                                                                                    });
                                                                                }
                                                                            });
                                                                            Strata = [...Strata, buildStrata];
                                                                        });
                                                                    }
                                                                });
                                                                HPs = [...HPs, buildHP];
                                                            });
                                                            LCT_Characteristics = [...LCT_Characteristics, ...Characteristics];
                                                            stratumCharacteristicNumber = LCT_Characteristics.length+1;
                                                            LCT_Properties = [...LCT_Properties, ...Properties];
                                                            stratumPropertyNumber = LCT_Properties.length+1;
                                                            LCT_Strata = [...LCT_Strata, ...Strata];
                                                            strataNumber = MaxStratumID;
                                                            LCT_HorizontalPatterns = [...LCT_HorizontalPatterns, ...HPs];
                                                            horizontalPatternNumber = MaxHPID;
                                                        }
                                                    });
                                                    classes.push(buildClass);
                                                });
                                                LCT_Class = classes;
                                                classesNumber = MaxClassID;
                                            }
                                        });
                                    });
                                }
                            }
                        });
                        Legends.current.push({[file.name]: { LCT_Legend: LCT_Legend, LCT_Class: LCT_Class, LCT_ClassCharacteristics: LCT_ClassCharacteristics, LCT_HorizontalPatterns: LCT_HorizontalPatterns, LCT_Strata: LCT_Strata, LCT_Properties: LCT_Properties, LCT_Characteristics: LCT_Characteristics}});
                    }
                    else
                        toast.current.show({ severity: 'error', summary: 'Incompatible File', detail: file.name+' is incompatible and will not be processed.', life: 5000 });                    
                };
                reader.readAsText(file);
                setTotalSize(file.size);
                setFileContent(Legends.current);
            }
        });
        const timer = setTimeout(() => {
            setUploadVisible(false);
            setComparatorVisible(true);
        }, 1000);           
    }
        
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef<FileUpload>(null);    
    const onTemplateClear = () => {
        setTotalSize(0);
        fileContent.current = {};
    };
    const onTemplateRemove = (file: File, callback: Function) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };
    const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
        const file = inFile as File;
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };
    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="fa-regular fa-file-code mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop Legend File Here
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'fa-regular fa-file-code', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cog', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    const customTheme = {
        attributeKeyColor: "#0074D9",
        attributeValueColor: "#2ECC40"
    };

    const [method, setMethod] = useState<string>('');

    /*useEffect(() => {
        var filterDiv = document.getElementById("filters");
        if(method === 'Correspondence'){            
            filterDiv.style.display = "block";
        } else {
            filterDiv.style.display = "none";
        }        
      }, [method]);*/

    interface Item {
        name: string;
        value: number;
    }
    const [value, setValue] = useState<Item>(null);
    const items: Item[] = [
        {name: 'Natural', value: 1},
        {name: 'Cultivated', value: 2}
    ];
    
    return (
        <>
            <div className="card m-0 p-0" style={{ height: '4vh', minHeight: '20px' }}>
                <img alt="Land CHaracterization Software" src="logo.png" height="30" className="mr-2"></img>            
                <img alt="Food and Agriculture Organization of the United Nations" src="fao-logo.png" height="30" className="mr-2"></img>
            </div>
            <div className="card flex flex-row gap-2 m-1 p-1">
                <div className="card flex flex-column justify-content-center align-self-start" style={{ width: '60%' }}>
                    <Card title="Similarity Assessment" className="w-full">
                        <Toast ref={toast}></Toast>
                        <Tooltip target=".custom-choose-btn" content="Select Legends" position="bottom" />
                        <Tooltip target=".custom-upload-btn" content="Process Legends" position="bottom" />
                        <Tooltip target=".custom-cancel-btn" content="Clear Loaded Legends" position="bottom" />
                        {/*<div className="card flex gap-1" style={{height: '30px'}}>
                            <div className="card flex">Compare Legends for similarity by:</div>
                            <div className="flex flex-wrap gap-3">
                                <div className="flex align-items-center">
                                    <RadioButton inputId="method1" name="method" value="Element Count" onChange={(e: RadioButtonChangeEvent) => setMethod(e.value)} checked={method === 'Element Count'} />
                                    <label htmlFor="method1" className="ml-2">Element Count</label>
                                </div>
                                <div className="flex align-items-center">
                                    <RadioButton inputId="method2" name="method" value="Correspondence" onChange={(e: RadioButtonChangeEvent) => setMethod(e.value)} checked={method === 'Correspondence'} />
                                    <label htmlFor="method2" className="ml-2">Correspondence</label>
                                </div>
                            </div>
                            <div id="filters" className="align-items-center" style={{display: 'none', textAlign: 'center'}}>
                                Vegetation Filter:<br/>
                                <SelectButton value={value} onChange={(e: SelectButtonChangeEvent) => setValue(e.value)} optionLabel="name" options={items} multiple />
                            </div>
                        </div>*/}
                        
                        <FileUpload name="Legend" accept=".lchs, .lccs, .xml, .csv" multiple maxFileSize={1000000} onSelect={(e: FileUploadSelectEvent)=>handleFileChange(e)} customUpload uploadHandler={(e: FileUploadHandlerEvent)=>importLegend(e)}
                            ref={fileUploadRef} onError={onTemplateClear} onClear={onTemplateClear} headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate} chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />                        
                        <XMLViewer xml={fileDisplay} theme={customTheme} initalCollapsedDepth={1} collapsible />

                    </Card>
                </div>
                <div className="card flex flex-column justify-content-center align-self-start gap-1" style={{ width: '40%' }}>
                    <Card title="File Types" className="w-full" style={{ background: '#eee' }}>
                        Rename the Reference Legend file to include &quot;reference&quot;
                        <table>
                            <tbody>
                                <tr>
                                    <td width='70px' valign='top'><b>LCHS Files:</b></td>
                                    <td><b>Natively Processed</b></td>
                                </tr>
                                <tr>
                                    <td width='70px' valign='top'><b>LCCS Files:</b></td>
                                    <td><b>Natively Processed</b><br />LCCS 3 and Land Cover Registry (LCLR) are natively processed</td>
                                </tr>
                                <tr>
                                    <td width='70px' valign='top'><b>CSV Files:</b></td>
                                    <td><b>Remap for Processing</b><br />
                                        <b>Data Fields: ID, Color Code(Hex), Class Code, Class Name, Class Description, Elements</b>                                        
                                        <p>Non native legends such as SEEA, Corine etc. should be reformatted to have these datafields for processing. (Note the Elements as separated with a colon &quot;;&quot;)</p> For Example:
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td>ID</td>
                                                    <td>Color Code(Hex)</td>
                                                    <td>Class Code</td>
                                                    <td>Class Name</td>
                                                    <td>Class Description</td>
                                                    <td>Elements</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>FF0000</td>
                                                    <td>211</td>
                                                    <td>Non-irrigated arable land</td>
                                                    <td>Cultivated land parcels under rainfed agricultural use</td>
                                                    <td>Vegetation; Growth Forms; Woody Growth Forms; Trees; Shrubs;</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>FF00FF</td>
                                                    <td>SEEA 1</td>
                                                    <td>Herbaceous crops</td>
                                                    <td>Herbaceous crops</td>
                                                    <td>Herbaceous Growth Forms; Graminae; Forbs; Grass;</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                </div>
            </div>
        </>
    );
};